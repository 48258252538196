import React, { cloneElement } from 'react';
import {
    useListContext,
    TopToolbar,
    sanitizeListRestProps,
    Button,
} from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';

import PostQuickCreateButton from './PostQuickCreateButton';

const QuestionListActions = (props) => {
    
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        // currentSort,
        resource,
        displayedFilters,
        filterValues,
        // hasCreate,
        basePath,
        // selectedIds,
        showFilter,
        // total,
    } = useListContext();

    return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
    })}

<Button
        onClick={() => { 

            var headers = new Headers({ Accept: 'application/json' });
        const token = localStorage.getItem('token');
        headers.set('Authorization', `JWT ${token}`);


        fetch(`${process.env.REACT_APP_API_URL}/questions/template`, { method: 'GET', headers: headers })
            .then(response => response.blob())
            .then((blob) => {

                /* LAUNCH THE DOWNLOAD */
                const fakeLink = document.createElement('a');
                fakeLink.style.display = 'none';
                document.body.appendChild(fakeLink);
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    // Manage IE11+ & Edge
                    window.navigator.msSaveOrOpenBlob(blob, `question_template.xlsx`);
                } else {
                    fakeLink.setAttribute('href', URL.createObjectURL(blob));
                    fakeLink.setAttribute('download', `question_template.xlsx`);
                    fakeLink.click();
                }

            })
            .catch((e) => {
                console.log('Warning: Error: comment not approved');
            });
        console.log('ApproveButton clicked')
         }}

        label="Get template"
        >
            <DownloadIcon />
        </Button>

        <PostQuickCreateButton />

    </TopToolbar>)

    };

export default QuestionListActions;