import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    NumberField,
    DateField,
    BooleanField,
    ReferenceField,
    ArrayField,
    Datagrid,
} from 'react-admin';
import { getFieldsForRessource } from '../fieldsForRessource';
import dateUtils from '../utils/dateUtils';
import utils from '../utils/utils';

class CustomTableShow extends React.Component {
    state = { data: null }

    ShowTitle = ({ record }) => {
        return <span>{this.props.resource} {record.number ? `${record.number}` : ``}</span>;
        // return <span>{this.props.resource} {record.number ? `${record.number}` : `${record.id}`}</span>;
    };

    lastCheckBeforeValidate(data) {
        return new Promise((resolve, reject) => {
              resolve({ data: <Show {...this.props}  title={<this.ShowTitle />}>
                    <SimpleShowLayout>
                        {data}
                    </SimpleShowLayout>
            </Show>});
          });
    }

    customHandled(fields, fieldName, testing) {
        return false
    }


    generateFromFields(fields) {
        let that = this

        var testing = []
        console.log('PASSED HERE')
        console.log(fields)
        for (const fieldName in fields) {

            if (!this.customHandled(fields, fieldName, testing)) {
                if (fields[fieldName].type === 'String') {
                    testing.push(<TextField source={fieldName}/>)
                } else if (fields[fieldName].type === 'Number') {
                    testing.push(<NumberField source={fieldName}/>)
                } else if (fields[fieldName].type === 'Date') {
                    testing.push(<DateField source={fieldName} format={dateUtils.dateFormatter} parse={dateUtils.dateParser} />)
                } else if (fields[fieldName].type === 'Boolean') {
                    testing.push(<BooleanField source={fieldName} />)
                } else if (fields[fieldName].type === 'ObjectId') {
                    const plural = fields[fieldName].ref + 's';
                    testing.push(<ReferenceField source={fieldName} reference={plural} link="show">
                        <TextField source={utils.displayFieldForPlural(plural)} />
                    </ReferenceField>)
                } else if (Array.isArray(fields[fieldName].type)) {

                    console.log(fields[fieldName].type)

                    var subObject = that.generateFromFields(fields[fieldName].type[0])
                        
                    testing.push(<ArrayField source={fieldName}>
                        <Datagrid>
                            {subObject}
                        </Datagrid>
                    </ArrayField>)

                    // testing.push(<ReferenceArrayField source={fieldName} reference={fieldName}>
                    //     <SingleFieldList>
                    //         {/* <ChipField source="name" /> */}
                    //         <ChipField source="name" />
                    //     </SingleFieldList>
                    // </ReferenceArrayField>)
                }
            }
        };
        return testing
    }

    componentDidMount() {
        return getFieldsForRessource(this.props.resource).then((fields) => {
            let that = this

            var testing = that.generateFromFields(fields)
    
            this.lastCheckBeforeValidate(testing).then((data) => {
                this.setState(data);
            })
        });
    }

    render() {
    
        if (!this.state.data) {
          return <div />
        }

        return this.state.data;
    }
}

export default CustomTableShow;
