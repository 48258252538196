export default {
    
    getPlural: (sigularWord) => {
        var lastChar = sigularWord.substr(sigularWord.length - 1);

        if (lastChar === 'y') {
            return `${sigularWord.substring(0, sigularWord.length -1)}ies`;
        }
        return `${sigularWord}s`;
    },

    displayFieldForPlural: (plural) => {
        let optionText = 'name';

        if (plural === 'videos' || plural === 'series') {
            optionText = 'title';
        } else if (plural === 'persons') {
            optionText = 'englishName';
        }
        console.log(`displayFieldForPlural ${plural} : return ${optionText}`);

        return optionText;
    }
}