import React from 'react';
import {
    ReferenceField,
    TextField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
} from 'react-admin';
import CustomTableList from './CustomTableList';


class LeagueList extends CustomTableList {

    customHandled(fields, fieldName, testing) {

        if (fieldName === 'creator') {
            testing.push(<ReferenceField source={fieldName} reference="persons" link="show">
                <TextField source="englishName" />
            </ReferenceField>)
            return true

        } else if (fieldName === 'members') {
            testing.push(<ReferenceArrayField source={fieldName} reference="persons">
                <SingleFieldList linkType="show">
                    <ChipField source="englishName" />
                </SingleFieldList>
            </ReferenceArrayField>)
            return true
        }
        return false
    }
}

export default LeagueList;
