import React from 'react';
import {
    TextField,
    ReferenceField,
} from 'react-admin';
import CustomTableShow from './CustomTableShow';

class SurveyShow extends CustomTableShow {

    customHandled(fields, fieldName, testing) {
        if (fieldName === 'latestAnswer' || fieldName === 'latestIsCorrect') {
            return true
        } else if (fieldName === 'personId') {
            const plural = fields[fieldName].ref + 's';
            testing.push(<ReferenceField source={fieldName} reference={plural} link="show">
                <TextField source="englishName" />
            </ReferenceField>)
            return true
        }
        return false
    }
}

export default SurveyShow;
