import React from 'react';
import {
    TextField,
    DateField,
    UrlField,
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ElipsizeTextField from '../fields/ElipsizeTextField'


class CareerList extends CustomTableList {

    ShowTitle = ({ record }) => {
        return <span>APM careers</span>;
    };

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'originalCreationDate') {
            fieldList.push(<DateField source={fieldName} label="Created at" showTime />)
            return true
        } else if (fieldName === 'originalID') {
            fieldList.push(<TextField source={fieldName} label="ID"/>)
            return true
        } else if (fieldName === 'description') {
            fieldList.push(<ElipsizeTextField source={fieldName} field={fieldName} maxLength={100}/>)
            return true
        } else if (fieldName === 'url') {
            fieldList.push(<UrlField source={fieldName} />)
            return true
        }
        return false
    }
}

export default CareerList;
