import React from 'react';
import { cloneElement } from 'react';
import {
    useListContext,
    TopToolbar,
    ExportButton,
    sanitizeListRestProps,
} from 'react-admin';


const ReportsExportAllActions = (props) => {
    
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();

    const permissions = localStorage.getItem('permissions');

    return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
    })}
    {/* <CreateButton basePath={basePath} /> */}

    { permissions === 'admin' && <ExportButton
                resource={resource}
                // sort={currentSort}
                filterValues={filterValues}
                maxResults={100000}
            />}

</TopToolbar>)


    };

export default ReportsExportAllActions;