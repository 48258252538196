import React from 'react';
import { cloneElement } from 'react';
import {
    useListContext,
    TopToolbar,
    CreateButton,
    Button,
    sanitizeListRestProps,
} from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';


const PersonsExportAllActions = (props) => {
    
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        // currentSort,
        resource,
        displayedFilters,
        filterValues,
        // hasCreate,
        basePath,
        // selectedIds,
        showFilter,
        // total,
    } = useListContext();

    const permissions = localStorage.getItem('permissions');

    return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
    })}
    {/* <CreateButton basePath={basePath} /> */}

    { permissions === 'admin' && <Button
        onClick={() => { 

            var headers = new Headers({ Accept: 'application/json' });
        const token = localStorage.getItem('token');
        headers.set('Authorization', `JWT ${token}`);


        fetch(`${process.env.REACT_APP_API_URL}/persons/export`, { method: 'GET', headers: headers })
            .then(response => response.blob())
            .then((blob) => {

                /* LAUNCH THE DOWNLOAD */
                const fakeLink = document.createElement('a');
                fakeLink.style.display = 'none';
                document.body.appendChild(fakeLink);
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    // Manage IE11+ & Edge
                    window.navigator.msSaveOrOpenBlob(blob, `Person_export_for_wechat_work.xlsx`);
                } else {
                    fakeLink.setAttribute('href', URL.createObjectURL(blob));
                    fakeLink.setAttribute('download', `Person_export_for_wechat_work.xlsx`);
                    fakeLink.click();
                }

            })
            .catch((e) => {
                console.log('Warning: Error: comment not approved');
            });
        console.log('ApproveButton clicked')
         }}

        label="Wechat work export"
        >
            <DownloadIcon />
        </Button>}

</TopToolbar>)


    };

export default PersonsExportAllActions;