import React from 'react';
import {
    Filter,
    ReferenceInput,
    AutocompleteInput,
    ReferenceField,
    TextField,
    TextInput,
    SelectInput,
} from 'react-admin';
import CustomTableList from './CustomTableList';

import ActiveField from '../fields/ActiveField'
import NoTimezoneDateField from '../fields/NoTimezoneDateField'

class RosterList extends CustomTableList {

    ShowTitle = ({ record }) => {
        return <span>Rosters</span>;
        // return <span>{this.props.resource} {record.number ? `${record.number}` : `${record.id}`}</span>;
    };

    myFilter = (props) => (
        <Filter {...props}>
            {/* <TextInput label="Search" source="name" alwaysOn /> */}
            <ReferenceInput label="Person" source="personId" reference="persons" allowEmpty >
                <AutocompleteInput optionText="englishName" />
            </ReferenceInput>

            <TextInput label="Person number" source="employeeID" />
            
            {/* <ReferenceInput label="Department" source="departmentId" reference="departments" allowEmpty >
                <AutocompleteInput optionText="departmentName" />
            </ReferenceInput> */}
{/* 
            <ReferenceInput label="Parent Department" source="parentDepartmentId" reference="departments" allowEmpty >
                <AutocompleteInput optionText="departmentName" />
            </ReferenceInput> */}
            {/* <TextInput label="Group type" source="groupType" /> */}
            {/* <SelectInput label="Status" source="zaizhiZhuangtai" allowEmpty
            choices={[
                { id: 'ACTIVE', name: 'Active' },
                { id: 'INACTIVE', name: 'Inactive' },
            ]}/> */}
        </Filter>
    );

    customHandled(fields, fieldName, testing) {
        if (fieldName === 'employeeID'){
            testing.push(<TextField label="Employee ID" source={fieldName}/>)
            return true
        } else if (fieldName === 'fromDateTime' || fieldName === 'toDateTime') {
            testing.push(<NoTimezoneDateField source={fieldName} locale="" showTime />)
            return true
        } else if (fieldName === 'year') {
            testing.push(<TextField label="Year" source={fieldName}/>)
            return true
        } else if (fieldName === 'personId') {
            const plural = fields[fieldName].ref + 's';

            testing.push(<ReferenceField label="Person" source="personId.id" sortable={false} reference={plural} link="show">
                <TextField source="englishName" />
            </ReferenceField>)

            testing.push(<ReferenceField label="Department" source="personId.departmentId.id" sortable={false} reference="departments" link="show">
                <TextField source="departmentName" />
            </ReferenceField>)

            testing.push(<TextField label="Parent Department" source="personId.departmentId.parentDepartmentName" sortable={false} />)

            testing.push(<ActiveField label="Status" source="personId.zaizhiZhuangtai" sortable={false} />)

            return true
        }
        return false
    }
}

export default RosterList;
