import React from 'react';
import {
    TextField,
    ReferenceField,
    Filter,
    ReferenceInput,
    AutocompleteInput,
    TextInput,
    useTranslate,
} from 'react-admin';
import { makeStyles, Chip } from '@material-ui/core';
import CustomTableList from './CustomTableList';

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

class PersonList extends CustomTableList {

    myFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Department name" source="q" />

            <ReferenceInput label="Parent Department" source="parentDepartmentLocalId" reference="departments" allowEmpty alwaysOn >
                {/* <SelectInput optionText="departmentName" /> */}
                <AutocompleteInput optionText="departmentName" />
            </ReferenceInput>

            <QuickFilter source="isShop" label="Shops only" defaultValue={true} />

            <TextInput label="Address" source="address" />

        </Filter>
    );

    customHandled(fields, fieldName, testing) {
        if (fieldName === 'departmentId' || fieldName === 'parentDepartmentId' ||
            fieldName === 'parentDepartmentName' || fieldName === 'zhuguanNumber') {
            return true
        } else if (fieldName === 'zhuguanName') {
            testing.push(<TextField label="Manager name" source={fieldName}/>)
            return true
        } else if (fieldName === 'zuzhiAddress') {
            testing.push(<TextField label="Address" source={fieldName}/>)
            return true
        } else if (fieldName === 'parentDepartmentLocalId') {
            testing.push(<ReferenceField label="Parent department" source={fieldName} reference='departments' sortable={false} link="show">
                <TextField source="departmentName" />
            </ReferenceField>)

            return true
        }
        return false
    }

    filterDefaultValues(props) {
        return { isShop: true };
    }

}

export default PersonList;
