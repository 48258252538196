import React from 'react';
import {
    Filter,
    ReferenceInput,
    AutocompleteInput,
    ReferenceField,
    TextField,
    DateInput,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import CustomTableList from './CustomTableList';
import downloadCSV from '../utils/customDownloadCSV'
import ReportsExportAllActions from '../actions/ReportsExportAllActions';

class ReportList extends CustomTableList {

    myFilter = (props) => (
        <Filter {...props}>
            <ReferenceInput label="Person" source="personId" reference="persons" allowEmpty >
                <AutocompleteInput optionText="englishName" />
            </ReferenceInput>

            {/* <ReferenceInput label="Department" source="departmentId" reference="departments" allowEmpty >
                <AutocompleteInput optionText="departmentName" />
            </ReferenceInput> */}
            
            <ReferenceInput label="Department" source="departmentId" reference="departments" allowEmpty >
                <AutocompleteInput optionText="departmentName" />
            </ReferenceInput>

            <ReferenceInput label="Parent Department" source="parentDepartmentId" reference="departments" allowEmpty >
                <AutocompleteInput optionText="departmentName" />
            </ReferenceInput>
            <DateInput label="Date (from)" source="dateFrom" />
            <DateInput label="Date (To)" source="dateTo" />
        </Filter>
    );

    canExport = (records, fetchRelatedRecords) => {
            
        console.log(records)
        const data = records.map(record => ({
            ...record,
            person: record.personId.englishName,
            department: record.personId.departmentId ? record.personId.departmentId.departmentName : "N/A",
            "parent department": record.personId.departmentId ? record.personId.departmentId.parentDepartmentName : "N/A",
        }));

        const postsForExport = data.map(post => {
            const { createdAt, updatedAt, id, personId, ...postForExport } = post; // omit createdAt and updatedAt
            return postForExport;
        });
        jsonExport(postsForExport, {
            headers: ['person', 'department', 'parent department', 'time', 'action' ],
        }, (err, csv) => {;
            downloadCSV(csv, 'comments');
        });
    };

    customHandled(fields, fieldName, testing) {
        if (fieldName === 'personId') {
            const plural = fields[fieldName].ref + 's';


            testing.push(<ReferenceField label="Person" source="personId.id" reference={plural} sortable={false} link="show">
                <TextField source="englishName" />
            </ReferenceField>)

            testing.push(<ReferenceField label="Department" source="personId.departmentId.id" reference="departments" sortable={false} link="show">
                <TextField source="departmentName" />
            </ReferenceField>)

            testing.push(<TextField label="Parent Department" source="personId.departmentId.parentDepartmentName" sortable={false} />)

            // testing.push(<ReferenceField label="Person" source={fieldName} reference={plural} link={null}>
            //     <ReferenceField source="id" reference="persons" link={null}>
            //         <TextField source="englishName" />
            //     </ReferenceField>
            // </ReferenceField>)

            // testing.push(<ReferenceField label="Department" source={fieldName} reference={plural} link={null}>
            //     <ReferenceField source="departmentId" reference="departments" link={null}>
            //         <TextField source="departmentName" />
            //     </ReferenceField>
            // </ReferenceField>)

            // testing.push(<ReferenceField label="Parent department" source={fieldName} reference={plural} link={null}>
            //     <ReferenceField source="departmentId" reference="departments" link={null}>
            //         <TextField source="parentDepartmentName" />
            //     </ReferenceField>
            // </ReferenceField>)

            return true
        }
        return false
    }

    customActions = (props) => {
        console.log("passed here")
        return <ReportsExportAllActions {...props}/>
    }

}

export default ReportList;
