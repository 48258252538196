import React from 'react';
import { Admin, Resource } from 'react-admin';

import QuestionIcon from '@material-ui/icons/ContactSupport';
import PersonIcon from '@material-ui/icons/Person';
import DepartmentIcon from '@material-ui/icons/Work';
import KpiIcon from '@material-ui/icons/Info';
import KpiWeightIcon from '@material-ui/icons/Height';
import LeagueIcon from '@material-ui/icons/People';
import RosterIcon from '@material-ui/icons/CalendarToday';
import PastRosterIcon from '@material-ui/icons/CalendarTodayOutlined';
import BalanceIcon from '@material-ui/icons/AccountBalance';
import CareerIcon from '@material-ui/icons/Assignment';
import LeaveIcon from '@material-ui/icons/TimeToLeave';
import ScoreIcon from '@material-ui/icons/Score';
import ReportIcon from '@material-ui/icons/Note';
import UserIcon from '@material-ui/icons/Lock';
import SurveyIcon from '@material-ui/icons/Check';

import authProvider from './authProvider';
import dataProvider from './dataProvider';

import MyLoginPage from './pages/MyLoginPage';


import UserCreate from './creates/UserCreate';
import UserList from './lists/UserList';
import UserEdit from './edits/UserEdit';
import UserShow from './shows/UserShow';

import KpiList from './lists/KpiList';
import KpiShow from './shows/KpiShow';

import KpiWeightList from './lists/KpiWeightList';
import KpiWeightShow from './shows/KpiWeightShow';

import LeagueList from './lists/LeagueList';
import LeagueEdit from './edits/LeagueEdit';
import LeagueShow from './shows/LeagueShow';

import RosterList from './lists/RosterList';
import PastRosterList from './lists/PastRosterList';
import BalanceList from './lists/BalanceList';
import LeaveList from './lists/LeaveList';

import ScoreCreate from './creates/ScoreCreate';
import ScoreEdit from './edits/ScoreEdit';
import ScoreList from './lists/ScoreList';
import ScoreShow from './shows/ScoreShow';

import SurveyList from './lists/SurveyList';
import SurveyShow from './shows/SurveyShow';

import DepartmentList from './lists/DepartmentList';
import DepartmentShow from './shows/DepartmentShow';

import CareerList from './lists/CareerList';
import CareerShow from './shows/CareerShow';

import ReportCreate from './creates/ReportCreate';
import ReportEdit from './edits/ReportEdit';
import ReportList from './lists/ReportList';
import ReportShow from './shows/ReportShow';

import './App.css';
import CustomTableCreate from './creates/CustomTableCreate';
import CustomTableEdit from './edits/CustomTableEdit';
import CustomTableShow from './shows/CustomTableShow';

import QuestionList from './lists/QuestionList';

import PersonList from './lists/PersonList';
import PersonShow from './shows/PersonShow';

import CustomTableList from './lists/CustomTableList';
import ApmTheme from './ApmTheme';

class App extends React.Component {

  render() {

  const ressources = []

  ressources.push()

  return <Admin
    // dashboard={Dashboard}
    loginPage={MyLoginPage}
    theme={ApmTheme}
    authProvider={authProvider}
    dataProvider={dataProvider}>
    {permissions => [

  permissions === 'admin'
  ? <Resource
    name={'questions'}
    create={permissions === 'admin' || permissions === 'hr' ? CustomTableCreate : null}
    list={QuestionList}
    edit={permissions === 'admin' || permissions === 'hr' ? CustomTableEdit : null}
    show={CustomTableShow}
    icon={QuestionIcon} /> : null,

  <Resource
    name={'persons'}
    options={{ label: 'Staff' }}
    // create={CustomTableCreate}
    list={PersonList}
    // edit={CustomTableEdit}
    show={PersonShow}
    icon={PersonIcon} />,

  <Resource
    name={'departments'}
    // create={CustomTableCreate}
    list={DepartmentList}
    // edit={CustomTableEdit}
    show={DepartmentShow}
    icon={DepartmentIcon} />,

  <Resource
    name={'kpis'}
    options={{ label: 'KPIs' }}
    // create={CustomTableCreate}
    list={KpiList}
    edit={CustomTableEdit}
    show={KpiShow}
    icon={KpiIcon} />,

    <Resource
    name={'kpi-weights'}
    options={{ label: 'KPI Weight' }}
    list={KpiWeightList}
    show={KpiWeightShow}
    icon={KpiWeightIcon} />,

    <Resource
    name={'leagues'}
    list={LeagueList}
    edit={LeagueEdit}
    show={LeagueShow}
    icon={LeagueIcon} />,

    permissions === 'admin' ? <Resource
    name={'rosters'}
    // create={CustomTableCreate}
    list={RosterList}
    // edit={CustomTableEdit}
    show={CustomTableShow}
    icon={RosterIcon} /> : null,

    permissions === 'admin' ? <Resource
    name={'past-rosters'}
    // create={CustomTableCreate}
    list={PastRosterList}
    // edit={CustomTableEdit}
    show={CustomTableShow}
    icon={PastRosterIcon} /> : null,

    permissions === 'admin' ? <Resource
    name={'leaves'}
    // create={CustomTableCreate}
    list={LeaveList}
    // edit={CustomTableEdit}
    show={CustomTableShow}
    icon={LeaveIcon} /> : null,


    permissions === 'admin' ? <Resource
    name={'balances'}
    options={{ label: 'Leave balances' }}
    // create={CustomTableCreate}
    list={BalanceList}
    // edit={CustomTableEdit}
    show={CustomTableShow}
    icon={BalanceIcon} /> : null,

    permissions === 'admin' ? <Resource
    name={'careers'}
    options={{ label: 'APM Career' }}
    list={CareerList}
    show={CareerShow}
    icon={CareerIcon} /> : null,

  <Resource
    name={'scores'}
    // create={ScoreCreate}
    list={ScoreList}
    // edit={ScoreEdit}
    show={ScoreShow}
    icon={ScoreIcon} />,

  <Resource
    name={'surveys'}
    list={SurveyList}
    show={SurveyShow}
    icon={SurveyIcon} />,

  permissions === 'admin' ? <Resource
    name={'reports'}
    // create={ReportCreate}
    list={ReportList}
    // edit={ReportEdit}
    show={ReportShow}
    icon={ReportIcon} /> : null,

  permissions === 'admin' ? <Resource
    name={'users'}
    create={permissions === 'admin' ? UserCreate : null}
    list={UserList}
    edit={permissions === 'admin' ? UserEdit : null}
    show={UserShow}
    icon={UserIcon} /> : null,
    ]}
  </Admin>
  }
}

require('dotenv').config()

export default App;
