import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { change, submit, isSubmitting } from "redux-form";
import {
  fetchEnd,
  fetchStart,
  required,
  showNotification,
  Button,
  SimpleForm,
  TextInput,
  FileInput,
  FileField,
  fetchUtils,
  HttpError,
} from "react-admin";

import ImportIcon from '@material-ui/icons/CloudUpload';
import IconSave from "@material-ui/icons/Save";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";


const apiUrl = process.env.REACT_APP_API_URL;

class PostQuickCreateButton extends Component {
    state = {
        error: false,
        showDialog: false,
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    httpClient = (url, options = {}) => {
        if (!options.headers) {
            options.headers = new Headers({ 'Content-Type': 'multipart/form-data'});
        }
        const token = localStorage.getItem('token');
        options.headers.set('Authorization', `JWT ${token}`);
        return fetchUtils.fetchJson(url, options);
    };

    httpClientFile = (url, file) => {
        return fetch(file)
        .then(r => {
            return r.blob()
        })
        .then(blob => {
            const formData  = new FormData();
            formData.append("file", blob)

            const token = localStorage.getItem('token');

            return fetch(url,  {
                method: 'POST',
                body: formData,
                headers: new Headers(
                    {
                        'Authorization': `JWT ${token}`
                    }
                )
            })
        })
        .then(response => {
            console.log(response)
            return response.text().then(text => {
                return {
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers,
                    body: text,
                }
             })
        })
        .then(({ status, statusText, headers, body }) => {
            let json;
            try {
                json = JSON.parse(body);
            } catch (e) {
                // not json, no big deal
            }
            if (status < 200 || status >= 300) {
                return Promise.reject(
                    new HttpError(
                        (json && json.message) || statusText,
                        status,
                        json
                    )
                );
            }

            return Promise.resolve({ status, headers, body, json })
        })
    };

    handleSaveClick = () => {
    };

    handleSubmit = values => {

        this.setState({ showDialog: false });

        this.httpClientFile(`${apiUrl}/questions/import?period=${values.target[0].value}`, values.target[1].form[2].nextElementSibling.firstChild.href)
        .then(({ json }) => {
            this.props.showNotification('Question imported')
        })

    };

    render() {
        const { showDialog } = this.state;
        const { isSubmitting } = this.props;

        return (
        <Fragment>
            <Button onClick={this.handleClick} label="Upload new questions">
                <ImportIcon />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={this.handleCloseClick}
                aria-label="Upload new questions" >
                <DialogTitle>Upload new questions</DialogTitle>
                <DialogContent>
                    
                    <SimpleForm
                    resource="questions"
                    onSubmit={(event) => this.handleSubmit(event)}
                    save={this.handleSaveClick}
                    toolbar={null}
                    redirect={false}
                    >
                    <TextInput source="period" label="Period (YYYY-MM)" value={this.state.period}  validate={required()} />
                    <FileInput source="file" label="Excel file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                            <FileField source="src" title="title" />
                        </FileInput>
                    <Button label="ra.action.save" saving={isSubmitting} type="submit">
                        <IconSave />
                    </Button>
                    </SimpleForm>

                </DialogContent>
            </Dialog>
        </Fragment>
        );
    }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting("post-quick-create")(state),
});

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostQuickCreateButton);