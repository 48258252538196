import React from 'react';
import {
    Datagrid,
    Show,
    SimpleShowLayout,
    TextField,
    ArrayField,
    Filter,
    TextInput,
} from 'react-admin';
import CustomTableList from './CustomTableList';
import QuestionListActions from '../actions/QuestionListActions';

class QuestionList extends CustomTableList {

    myFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Period" source="period" />
        </Filter>
    );


    customHandled(fields, fieldName, testing) {
        if (fieldName === 'choices') {
            return true
        }
        return false
    }

    PostShow(props){
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout>
        
                <ArrayField source="choices">
                    <Datagrid>
                        <TextField source="shortAnswer" />
                        <TextField source="longAnswerEnglish" />
                        <TextField source="longAnswerChinese" />
                        <TextField source="longAnswerChineseTraditional" />
                    </Datagrid>
                </ArrayField>

            </SimpleShowLayout>
        </Show>
    }

    customActions = (props) => {
        console.log("passed here")
        return <QuestionListActions {...props}/>
    }
}

export default QuestionList;
