import React from 'react';
import {
    Filter,
    ReferenceInput,
    AutocompleteInput,
    ReferenceField,
    TextField,
    TextInput,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import CustomTableList from './CustomTableList';
import downloadCSV from '../utils/customDownloadCSV'

class SurveyList extends CustomTableList {

    myFilter = (props) => (
        <Filter {...props}>
            <ReferenceInput label="Person" source="personId" reference="persons" allowEmpty >
                <AutocompleteInput optionText="englishName" />
            </ReferenceInput>

            <ReferenceInput label="Department" source="departmentId" reference="departments" allowEmpty >
                <AutocompleteInput optionText="departmentName" />
            </ReferenceInput>

            <ReferenceInput label="Parent Department" source="parentDepartmentId" reference="departments" allowEmpty >
                <AutocompleteInput optionText="departmentName" />
            </ReferenceInput>
        </Filter>
    );


    customHandled(fields, fieldName, testing) {
        if (fieldName === 'latestAnswer' || fieldName === 'latestIsCorrect') {
            return true
        } else if (fieldName === 'personId') {
            const plural = fields[fieldName].ref + 's';


            // testing.push(<ReferenceField source={fieldName} reference={plural} link="show">
            //     <TextField source="englishName" />
            // </ReferenceField>)

            testing.push(<ReferenceField label="Person" source="personId.id" reference={plural} sortable={false} link="show">
                <TextField source="englishName" />
            </ReferenceField>)

            testing.push(<ReferenceField label="Department" source="personId.departmentId.id" reference="departments" sortable={false} link="show">
            <TextField source="departmentName" />
            </ReferenceField>)

            testing.push(<TextField label="Parent Department" source="personId.departmentId.parentDepartmentName" sortable={false} />)
            return true
        }
        return false
    }
}

export default SurveyList;
