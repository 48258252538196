import React from 'react';
import {
    Filter,
    ReferenceInput,
    AutocompleteInput,
    ReferenceField,
    TextField,
    TextInput,
    NumberField,
    DateField,
    SelectInput,
} from 'react-admin';
import CustomTableList from './CustomTableList';

import ActiveField from '../fields/ActiveField'

class LeaveList extends CustomTableList {

    ShowTitle = ({ record }) => {
        return <span>Leaves</span>;
        // return <span>{this.props.resource} {record.number ? `${record.number}` : `${record.id}`}</span>;
    };

    myFilter = (props) => (
        <Filter {...props}>
            {/* <TextInput label="Search" source="name" alwaysOn /> */}
            <ReferenceInput label="Person" source="personId" reference="persons" allowEmpty >
                <AutocompleteInput optionText="englishName" />
            </ReferenceInput>

            <TextInput label="Person number" source="personNumber" />
            
            <ReferenceInput label="Department" source="departmentId" reference="departments" allowEmpty >
                <AutocompleteInput optionText="departmentName" />
            </ReferenceInput>

            <ReferenceInput label="Parent Department" source="parentDepartmentId" reference="departments" allowEmpty >
                <AutocompleteInput optionText="departmentName" />
            </ReferenceInput>
            {/* <TextInput label="Group type" source="groupType" /> */}
            <SelectInput label="Status" source="zaizhiZhuangtai" allowEmpty
            choices={[
                { id: 'ACTIVE', name: 'Active' },
                { id: 'INACTIVE', name: 'Inactive' },
            ]}/>
        </Filter>
    );

    customHandled(fields, fieldName, testing) {
        if (
            fieldName === 'personNumber') {
            return true

        } else if (fieldName === 'personId') {
            const plural = fields[fieldName].ref + 's';

            testing.push(<ReferenceField label="Person" source="personId.id" sortable={false} reference={plural} link="show">
                <TextField source="englishName" />
            </ReferenceField>)

            testing.push(<ReferenceField label="Department" source="personId.departmentId.id" sortable={false} reference="departments" link="show">
                <TextField source="departmentName" />
            </ReferenceField>)

            testing.push(<TextField label="Parent Department" source="personId.departmentId.parentDepartmentName" sortable={false} />)

            testing.push(<ActiveField label="Status" source="personId.zaizhiZhuangtai" sortable={false} />)

            return true
        }
        return false
    }
}

export default LeaveList;
