import React from 'react';
import {
    Filter,
    SelectInput,
} from 'react-admin';
import CustomTableList from './CustomTableList';

class KpiWeightList extends CustomTableList {

    ShowTitle = ({ record }) => {
        return <span>KPI Weights</span>;
    };

    myFilter = (props) => (
        <Filter {...props}>
            <SelectInput label="Status" source="active" allowEmpty
            choices={[
                { id: '1', name: 'Active' },
                { id: '0', name: 'Inactive' },
            ]}/>
        </Filter>
    );

}

export default KpiWeightList;
