import React from 'react';
import {
    ReferenceField,
    TextField,
} from 'react-admin';
import CustomTableList from './CustomTableList';

class UserList extends CustomTableList {

    customHandled(fields, fieldName, testing) {
        if (
            // fieldName === 'role' || 
            fieldName === 'password' || 
            fieldName === 'disabled') {
            return true
        } else if (fieldName === 'staff') {
            testing.push(<ReferenceField label="Staff" source="staff" reference="persons" sortable={false} link="show">
                <TextField source="englishName" />
            </ReferenceField>)
            return true

        }
        return false
    }

    filterDefaultValues(props) {
        return { disabled: false };
    }

}

export default UserList;
