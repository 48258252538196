import React from 'react';
import {
    // AutocompleteInput,
    // ReferenceInput,
    SelectInput
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';

class PictureEdit extends CustomTableEdit {

    customHandled(fields, fieldName, testing) {
        if (
            // fieldName === "role" ||
            fieldName === "disabled") {
            console.log(`I handle ${fieldName}`)
            return true
        }
         else if (fieldName === "staff") {
            // testing.push(<ReferenceInput source={fieldName} reference="persons" allowEmpty>
            //     <AutocompleteInput optionText="englishName" />
            // </ReferenceInput>)
            return true;
        } else if (fieldName === 'role') {
            testing.push(<SelectInput label="Role" source={fieldName} choices={[ { "id": "admin", "name": "Admin"} , { "id": "area_manager", "name": "Area manager"}]} />)
            return true;
        }
        return false
    }

}

export default PictureEdit;
