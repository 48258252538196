import React from 'react';
import {
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';

// FOR FUTURE USE
// const transformInChoices = base => {
//     let returnObject = []
//     for (const obj in base) {
//         if (base[obj] !== 'super-admin') {
//             returnObject.push({
//                 "id": base[obj],
//                 "name": base[obj]
//             })
//         }
//     }
//     return returnObject;
// }

class UserCreate extends CustomTableCreate {

    customHandled(fields, fieldName, testing) {
        // if (fields[fieldName].type === 'String' && fields[fieldName].enum !== undefined && fieldName === 'role') {
        //     // testing.push(<SelectInput label={fieldName} source={fieldName} choices={transformInChoices(fields[fieldName].enum)} />)

        //     return true
        // } else
         if (fieldName === 'hash' || fieldName === 'disabled') {
            return true
        } else if (fieldName === "staff") {
            testing.push(<ReferenceInput source={fieldName} reference="persons" allowEmpty>
                <AutocompleteInput optionText="englishName" />
            </ReferenceInput>)
            return true;
        } else if (fieldName === 'role') {
            testing.push(<SelectInput label="Role" source={fieldName} choices={[ { "id": "admin", "name": "Admin"} , { "id": "area_manager", "name": "Area manager"}]} />)
            return true;
        }
        return false
    }
}

export default UserCreate;
