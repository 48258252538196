import React from 'react';

const NoTimezoneField = props => {

    var date = new Date(props.record[props.source])
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset())


    var month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    var day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    var year = date.getFullYear()
    var hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    var minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    var second = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()

    return (
        <p>{month}/{day}/{year}, {hour}:{minute}:{second}</p>
    );
};

export default NoTimezoneField;
