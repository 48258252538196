import React from 'react';
import {
    ReferenceArrayInput,
    AutocompleteArrayInput,
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';

class LeagueEdit extends CustomTableEdit {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === "code" || fieldName === "creator") {
            return true
        } else if (fieldName === "members") {

            fieldList.push(<ReferenceArrayInput source={fieldName} reference="persons" filter={{"zaizhiZhuangtai": "ACTIVE"}} allowEmpty>
                <AutocompleteArrayInput optionText="englishName" />
            </ReferenceArrayInput>)
            return true
            
        }
        return false
    }

}

export default LeagueEdit;
