import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    NumberField,
    DateField,
    ReferenceField,
} from 'react-admin';
import dateUtils from '../utils/dateUtils';

class KpiShow extends React.Component {
    state = { data: null }

    ShowTitle = ({ record }) => {
        return <span>KPIs</span>;
    };

    componentDidMount() {
    
        this.setState({data: 
        <Show {...this.props}  title={<this.ShowTitle />}>
            <SimpleShowLayout>
            <ReferenceField label="Person" source='personId' reference='persons' link="show" className="ra-field-33">
                <TextField source="englishName" />
            </ReferenceField>)

            <ReferenceField label="Person Number" source='personId' reference='persons' link={null} className="ra-field-33">
                <TextField source="personNumber" />
            </ReferenceField>)

            <TextField source='groupType' className="ra-field-clear ra-field-33"/>

            <TextField label="Year" source='groupTypeYear' className="ra-field-clear ra-field-33"/>
            <TextField label="Month" source='groupTypeMonth' className="ra-field-33"/>
            <TextField label="Week" source='groupTypeWeek' className="ra-field-33"/>

            <DateField source='startDate' format={dateUtils.dateFormatter} parse={dateUtils.dateParser} className="ra-field-33"/>
            <DateField source='endDate' format={dateUtils.dateFormatter} parse={dateUtils.dateParser} className="ra-field-33"/>

            <NumberField label="CVR" source='cvr' className="ra-field-clear ra-field-33"/>
            <NumberField label="SPT" source='spt' className="ra-field-33"/>
            <NumberField label="LE" source='le' className="ra-field-33"/>

            <NumberField label="TOOBJ" source='toobj' className="ra-field-clear ra-field-33"/>
            <NumberField label="Bonus" source='bonus' className="ra-field-33"/>

            </SimpleShowLayout>
        </Show>});
    }

    render() {
    
        if (!this.state.data) {
          return <div />
        }

        return this.state.data;
    }
}

export default KpiShow;
