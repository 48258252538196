import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    ReferenceField,
} from 'react-admin';
import dateUtils from '../utils/dateUtils';

class PersonShow extends React.Component {
    state = { data: null }

    ShowTitle = ({ record }) => {
        return <span>{this.props.resource} {record.number ? `${record.number}` : ``}</span>;
    };

    componentDidMount() {
    
        this.setState({data: 
        <Show {...this.props}  title={<this.ShowTitle />}>
            <SimpleShowLayout>

            <TextField source='displayName' className="ra-field-50"/>
            <TextField source='englishName' className="ra-field-50"/>

            <TextField source='phoneNumber' className="ra-field-clear ra-field-50"/>
            <TextField source='email' className="ra-field-50"/>

            <TextField label='Gender' source='sex' className="ra-field-clear ra-field-50"/>
            <DateField label="Join date" source='joinUnitDate1' format={dateUtils.dateFormatter} parse={dateUtils.dateParser} className="ra-field-50" />

            <TextField label="Work address" source='workAddress' className="ra-field-clear ra-field-50"/>
            <TextField label="District" source='quyu' className="ra-field-50"/>

            <ReferenceField label="Department" source='departmentId' reference="departments" link="show" className="ra-field-clear ra-field-50">
                <TextField source="departmentName" />
            </ReferenceField>
            <TextField label="Manager" source='managerName' className="ra-field-50"/>


            <TextField label="Position" source='zhiweiName' className="ra-field-clear ra-field-50"/>
            <TextField source='employeeCategory' className="ra-field-50"/>

            <TextField label="Nav ID" source='navId' className="ra-field-clear ra-field-50"/>
            <TextField source='personNumber' className="ra-field-50"/>

            </SimpleShowLayout>
        </Show>});
    }

    render() {
    
        if (!this.state.data) {
          return <div />
        }

        return this.state.data;
    }
}

export default PersonShow;
