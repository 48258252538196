import React from 'react';
import {
    SelectField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';


const useStyles = makeStyles({
    ok: { color: 'green' },
    error: { color: 'red' },
    warning: { color: 'black' },
});

const ActiveField = props => {
    const classes = useStyles();
    return (
        <SelectField
            className={classnames({
                [classes.ok]: props.record[props.source] === "ACTIVE" || props.record[props.source] === "在职",
                [classes.error]: props.record[props.source] === "INACTIVE" || props.record[props.source] === "离职",
                [classes.warning]: props.record[props.source] !== "ACTIVE" && props.record[props.source] !== "在职" && props.record[props.source] !== "INACTIVE" && props.record[props.source] !== "离职",
            })}
            choices={[
                { id: "ACTIVE", name: 'ACTIVE - 在职' },
                { id: "在职", name: 'ACTIVE - 在职'  },
                { id: "INACTIVE", name: 'INACTIVE - 离职' },
                { id: "离职", name: 'INACTIVE - 离职' },
            ]}
            {...props}
        />
    );
};

export default ActiveField;
