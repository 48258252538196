import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
} from 'react-admin';

class DepartmentShow extends React.Component {
    state = { data: null }

    ShowTitle = ({ record }) => {
        return <span>{this.props.resource} {record.number ? `${record.number}` : ``}</span>;
    };

    componentDidMount() {
    
        this.setState({data: 
        <Show {...this.props}  title={<this.ShowTitle />}>
            <SimpleShowLayout>

            <TextField label="Department" source='departmentName' className="ra-field-50"/>
            <TextField label="Department ID" source='departmentId' className="ra-field-50"/>

            <TextField label="Address" source='zuzhiAddress' className="ra-field-clear" />

            <ReferenceField label="Parent department" source='parentDepartmentLocalId' reference='departments' link="show" className="ra-field-clear ra-field-50">
                <TextField source="departmentName" />
            </ReferenceField>
            <ReferenceField label="Parent department ID" source='parentDepartmentLocalId' reference='departments' link={null} className="ra-field-50">
                <TextField source="departmentId" />
            </ReferenceField>


            <TextField label="Manager name" source='zhuguanName' className="ra-field-clear ra-field-50" />
            <TextField label="Manager person number" source='zhuguanNumber' className="ra-field-50" />

            </SimpleShowLayout>
        </Show>});
    }

    render() {
    
        if (!this.state.data) {
          return <div />
        }

        return this.state.data;
    }
}

export default DepartmentShow;
