import React from 'react';
import {
    TextField,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
} from 'react-admin';
import CustomTableShow from './CustomTableShow';

class LeagueShow extends CustomTableShow {

    customHandled(fields, fieldName, testing) {

        if (fieldName === 'creator') {
            const plural = fields[fieldName].ref + 's';
            testing.push(<ReferenceField source={fieldName} reference="persons" link="show">
                <TextField source="englishName" />
            </ReferenceField>)
            return true

        } else if (fieldName === 'members') {

            testing.push(<ReferenceArrayField source={fieldName} reference="persons" link="show">
                <SingleFieldList>
                    <ChipField source="englishName" />
                </SingleFieldList>
            </ReferenceArrayField>)
            return true
        }
        return false
    }
}

export default LeagueShow;
