import React from 'react';
import CustomTableShow from './CustomTableShow';

class KpiWeightShow extends CustomTableShow {

    ShowTitle = ({ record }) => {
        return <span>KPI Weights</span>;
    };

}

export default KpiWeightShow;
