import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    NumberField,
    DateField,
    BooleanField,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Filter,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    TextInput,
    // SearchInput,
} from 'react-admin';

import CustomTableList from './CustomTableList';
import PersonsExportAllActions from '../actions/PersonsExportAllActions';

import ActiveField from '../fields/ActiveField'


class PersonList extends CustomTableList {

    collapsedView = []

    myFilter = (props) => (
        <Filter {...props}>
            <TextInput label="English name" source="q" />
            <ReferenceInput label="Department" source="departmentId" reference="departments" allowEmpty >
                {/* <SelectInput optionText="departmentName" /> */}
                <AutocompleteInput optionText="departmentName" />
            </ReferenceInput>
            <ReferenceInput label="Parent Department" source="parentDepartmentId" reference="departments" allowEmpty >
                <AutocompleteInput optionText="departmentName" />
            </ReferenceInput>
            {/* <SearchInput source="englishName" alwaysOn /> */}
            <TextInput label="Person number" source="personNumber" />
            {/* <TextInput label="Email" source="email" /> */}
            <SelectInput label="Status" source="zaizhiZhuangtai" allowEmpty
            choices={[
                { id: 'ACTIVE', name: 'Active' },
                { id: 'INACTIVE', name: 'Inactive' },
            ]} alwaysOn/>
            <TextInput label="Position" source="position" />
            <TextInput label="Employee category" source="employeeCategory" />
        </Filter>
    );

    customHandled(fields, fieldName, testing) {
        let that = this
        if (fieldName === 'personId') {
            return true
        } else if (fieldName === 'zhiweiName') {
            testing.push(<TextField label="Position" source={fieldName}/>)
            return true
        } else if (fieldName === 'zaizhiZhuangtai') {
            testing.push(<ActiveField label="Status" source={fieldName} sortable={false}/>)
            return true
        } else if (
            fieldName === 'personNumber' ||
            // fieldName === 'displayName' ||
            // fieldName === 'englishName' ||
            fieldName === 'phoneNumber' ||
            // fieldName === 'email' ||
            fieldName === 'sex' ||
            fieldName === 'joinUnitDate3' ||
            fieldName === 'joinUnitDate1' ||
            fieldName === 'workAddress' ||
            fieldName === 'quyu' ||
            fieldName === 'lizhiDate' ||
            fieldName === 'string14' ||
            fieldName === 'string15' ||
            // fieldName === 'zaizhiZhuangtai' ||
            fieldName === 'zuzhiCode' ||
            fieldName === 'navId' ||
            fieldName === 'eLeaning' ||
            fieldName === 'managerName' ||
            fieldName === 'managerNumber' ||
            fieldName === 'shebaoAddress' ||
            fieldName === 'nianjiaFuli' ||
            fieldName === 'jianyiDate' ||
            fieldName === 'hukouType' ||
            // fieldName === 'employeeCategory' ||
            fieldName === 'jobCode' ||
            fieldName === 'staffCode' ||
            fieldName === 'nationalityCode' ||
            fieldName === 'nationality' ||
            fieldName === 'timezone' ||
            fieldName === 'countryCodeNumber'
        ) {
            if (fields[fieldName].type === 'String') {
                that.collapsedView.push(<TextField source={fieldName}/>)
            } else if (fields[fieldName].type === 'Number') {
                that.collapsedView.push(<NumberField source={fieldName}/>)
            } else if (fields[fieldName].type === 'Date') {
                that.collapsedView.push(<DateField source={fieldName} showTime />)
            } else if (fields[fieldName].type === 'Boolean') {
                that.collapsedView.push(<BooleanField source={fieldName} />)
            } else if (fields[fieldName].type === 'ObjectId') {
                const plural = fields[fieldName].ref + 's';
                that.collapsedView.push(<ReferenceField source={fieldName} reference={plural} link="show">
                    <TextField source="name" />
                </ReferenceField>)
            } else if (Array.isArray(fields[fieldName].type)) {
                that.collapsedView.push(<ReferenceArrayField source={fieldName} reference={fieldName}>
                    <SingleFieldList>
                        {/* <ChipField source="name" /> */}
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>)
            }
            return true
        } else if (fieldName === 'departmentId') {
            testing.push(<ReferenceField label="Department" source="departmentId.id" reference="departments" sortable={false} link="show">
                <TextField source="departmentName" />
            </ReferenceField>)

            testing.push(<ReferenceField label="Parent Department" source="departmentId.parentDepartmentLocalId" reference="departments" sortable={false} link="show">
                <TextField source="departmentName" />
            </ReferenceField>)
            return true

        }
        return false
    }

    PostShow = (props) => {


        let views = this.collapsedView
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout>
        
                {views}

            </SimpleShowLayout>
        </Show>
    }

    customActions = (props) => {
        console.log("passed here")
        return <PersonsExportAllActions {...props}/>
    }

    filterDefaultValues(props) {
        console.log("passed here")
        return { zaizhiZhuangtai: "ACTIVE" };
    }
}

export default PersonList;
